<template>
  <div class="homeContainer">
    <div class="topBanner">
      <div class="logoutStyle" @click="changeView('')">Logout</div>
      <div
        style="display: flex; align-items: center; margin-left: 150px"
        v-if="user_type === 'student'"
      >
        <div
          :class="{
            navInBox: true,
            selected: currentNav === item.router,
          }"
          v-for="item in nav"
          :key="item.name"
          @click="$router.push(item.router)"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        style="display: flex; align-items: center; margin-left: 150px"
        v-if="user_type === 'educator'"
      >
        <div
          :class="{
            navInBox: true,
            selected: currentNav === item.router,
          }"
          v-for="item in navContact"
          :key="item.name"
          @click="$router.push(item.router)"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        style="display: flex; align-items: center; margin-left: 150px"
        v-if="user_type === 'employee'"
      >
        <div
          :class="{
            navInBox: true,
            selected: currentNav === item.router,
          }"
          v-for="item in navEmployee"
          :key="item.name"
          @click="$router.push(item.router)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- <div style="z-index: 3;display: flex;flex-direction: row;align-items: center;padding-left: 43px">
        <img v-if="avatar_url ==undefined || avatar_url == ''" src="../assets/default_avatar.png"
             style="width: 62px;height: 62px;border-radius: 31px">
        <img v-else :src="avatar_url" style="width: 62px;height: 62px;border-radius: 31px">
        <div style="margin-left: 10px;display: flex;flex-direction: column">
          <div style="font-size: 16px;color: white;font-weight: 600">{{ student_name }}</div>
          <div style="font-size: 16px;color: white;font-weight: 600">{{ student_school }}</div>
        </div>
        <div style="margin-left: 40px">
          <div style="font-size: 2vw;color: white;font-weight: 600">2021-2022 FBLA China<br>
            National Leadership Conference and Economics Challenge
          </div>
        </div>
      </div> -->
    </div>
    <div class="main">
      <!-- <div class="mainLeft">
        <div v-if="user_type==='student'" class="mainLeftContent">
          <div v-for="item in nav" :key="item.name" class="navOutBox" @click="changeView(item)">
            <div :class="{'navInBox':true,'selected':currentNav===item.router}">
              <img :src="item.selected" class="iconImg" v-if="currentNav===item.router"/>
              <img :src="item.unselected" class="iconImg" v-else/>
              {{ item.name }}
            </div>
          </div>
          <div class="content-logout" @click="changeView('')">
            <img src="../assets/nav/tuichu.png" class="iconImg"/>
            退出登录
          </div>
        </div>
        <div v-if="user_type==='educator'" class="mainLeftContent">
          <div v-for="item in navContact" :key="item.name" class="navOutBox" @click="changeView(item)">
            <div :class="{'navInBox':true,'selected':currentNav===item.router}">
              <img :src="item.selected" class="iconImg" v-if="currentNav===item.router"/>
              <img :src="item.unselected" class="iconImg" v-else/>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div v-if="user_type==='employee'" class="mainLeftContent">
          <div v-for="item in navEmployee" :key="item.name" class="navOutBox" @click="changeView(item)">
            <div :class="{'navInBox':true,'selected':currentNav===item.router}">
              <img :src="item.selected" class="iconImg" v-if="currentNav===item.router"/>
              <img :src="item.unselected" class="iconImg" v-else/>
              {{ item.name }}
            </div>
          </div>
        </div>
      </div> -->
      <div class="mainRight">
        <router-view></router-view>
      </div>
    </div>
    <div style="clear: both"></div>
    <div class="bottomView">
      <div v-show="!isChrome" class="navigator-tip-1">
        ALERT: you are NOT using Google Chrome to visit this site, which might
        cause unexpected misfunctions or bugs. The developer of this system
        requires visiting via Chrome and will not be responsible for any errors
        on non-Chrome platforms.
      </div>
    </div>
  </div>
</template>

<script>
import {
  logout,
  getUserType,
  getUserName,
  getAccountName,
  getAvatarUrl,
  getBrowserType,
} from "../utils/store";
import "../assets/common/font.css";

export default {
  data() {
    return {
      nav: [
        // {
        //   name: '官方公告',
        //   selected: require('../assets/nav/peixun1.png'),
        //   unselected: require('../assets/nav/peixun.png'),
        //   router: '/announcements'
        // },
        {
          name: "挑战项目",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/sessionlist",
        },
        {
          name: "讲座直播",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/lecturelist",
        },
        {
          name: "案例分析",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/caselist",
        },
        {
          name: "成绩公布",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/scoreList",
        },

        
        // {
        //   name: '成绩排行',
        //   selected: require('../assets/nav/chengji1.png'),
        //   unselected: require('../assets/nav/chengji.png'),
        //   router: '/score'
        // },
        // {
        //   name: '个人信息',
        //   selected: require('../assets/nav/geren1.png'),
        //   unselected: require('../assets/nav/geren.png'),
        //   router: '/personalinfo'
        // },
        // {
        //   name: '消息',
        //   selected: require('../assets/nav/xiaoxi1.png'),
        //   unselected: require('../assets/nav/xiaoxi.png'),
        //   router: '/message'
        // },
        // {
        //   name: '挑战成绩',
        //   selected: require('../assets/nav/award1.png'),
        //   unselected: require('../assets/nav/award.png'),
        //   router: '/award'
        // },
        //奖项
        // {name:'消息社交',selected:require('../assets/nav/award1.png'),unselected:require('../assets/nav/award.png'),router:'/award'},//奖项
        // {name: '退出登录', selected: require('../assets/nav/tuichu1.png'), unselected: require('../assets/nav/tuichu.png')}
      ],
      navContact: [
        {
          name: "讲座直播",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/teacherlist",
        },
        // {
        //   name: "个人信息",
        //   selected: require("../assets/nav/geren1.png"),
        //   unselected: require("../assets/nav/geren.png"),
        //   router: "/personalinfo",
        // },
        // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
        // {
        //   name: "退出登录",
        //   selected: require("../assets/nav/tuichu1.png"),
        //   unselected: require("../assets/nav/tuichu.png"),
        // },
      ],
      navEmployee: [
        {
          name: "讲座直播",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/employeelist",
        },
        // {
        //   name: "赛前培训",
        //   selected: require("../assets/nav/peixun1.png"),
        //   unselected: require("../assets/nav/peixun.png"),
        //   router: "/peixunadmin",
        // },
        // {
        //   name: "比赛项目",
        //   selected: require("../assets/nav/bisai1.png"),
        //   unselected: require("../assets/nav/bisai.png"),
        //   router: "/employeelist",
        // },
        // {
        //   name: "个人信息",
        //   selected: require("../assets/nav/geren1.png"),
        //   unselected: require("../assets/nav/geren.png"),
        //   router: "/personalinfo",
        // },
        // {
        //   name: "监考",
        //   selected: require("../assets/nav/bisai1.png"),
        //   unselected: require("../assets/nav/bisai.png"),
        //   router: "/examadminlist",
        // },
        // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
        // {
        //   name: "退出登录",
        //   selected: require("../assets/nav/tuichu1.png"),
        //   unselected: require("../assets/nav/tuichu.png"),
        // },
      ],
      user_type: getUserType(),
      currentNav: "/sessionlist",
      student_name: "",
      student_school: "",
      application_project: "",
      avatar_url: "",
      isChrome: false,
    };
  },
  watch: {
    $route(to, from) {
      this.currentNav = this.$route.path;
    },
  },
  mounted() {
    let browser = getBrowserType();
    if (browser === "CHROME") {
      this.isChrome = true;
    }
    this.student_name = getUserName();
    this.student_school = getAccountName();
    this.avatar_url = getAvatarUrl();
    this.currentNav = this.$route.path;
    // this.showView();
  },
  methods: {
    logout() {
      logout();
      location.reload();
    },
    showView() {
      this.$router.push("/sessionlist");
    },
    changeView(item) {
      if (item.router) {
        this.currentNav = item.router;
        this.$router.push(item.router);
      } else {
        this.$confirm("Are you sure you want to exit?", "Tips", {
          confirmButtonText: "confirm",
          cancelButtonText: "cancel",
          type: "warning",
        }).then(() => {
          this.logout();
        });
      }
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
.navigator-tip-1 {
  position: absolute;
  height: 54px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  color: white;
  font-size: 14px;
  text-align: right;
  width: 100%;
}
.content-logout {
  box-sizing: border-box;
  width: 380px;
  position: absolute;
  bottom: 0;
  padding-left: 70px;
  height: 10vh;
  background-color: #577bbc;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 2vh;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);

  .iconImg {
    height: 3vh;
    margin-right: 6px;
  }
}

.logoutStyle {
  // margin-left: 50px;
  right: 200px;
  position: absolute;
  z-index: 3;
  width: 60px;
  color: white;
  height: 15px;
  border: 1px solid white;
  text-align: center;
  font-size: 10px;
  line-height: 15px;
  font-family: Montserrat-SemiBold;
}

.homeContainer {
  .topBanner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 50px;
    background: #218393;
    .navInBox {
      height: 28px;
      color: white;
      display: flex;
      font-family: Microsoft YaHei;
      font-weight: bold;
      cursor: pointer;
      margin-right: 20px;
    }

    .selected {
      border-bottom: 2px solid white;
    }
  }

  .main {
    margin: 0;
    margin-top: -1px;
    padding: 0;
    display: flex;
    height: calc(100vh - 100px);
    position: relative;
    background-color: #0e409f;

    .mainBack {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .mainLeft {
      //#FF9900;
      background-color: #0e409f;
      width: 380px;

      .mainLeftContent {
        .navOutBox {
          width: 380px;
          height: 10vh;
          background: #0e409f;
          display: flex;
          flex-direction: row;
          cursor: pointer;

          .navInBox {
            width: 380px;
            padding-left: 70px;
            height: 10vh;
            align-items: center;
            justify-content: flex-start;
            background: #0e409f;
            display: flex;
            font-size: 2vh;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: rgba(255, 255, 255, 0.6);

            .iconImg {
              height: 3vh;
              margin-right: 6px;
            }
          }

          .selected {
            background-color: #577bbc;
            color: white;
          }
        }
      }
    }

    .mainRight {
      // background-image: url("../assets/magin_right_bg.png");
      background-repeat: no-repeat;
      background-color: white;
      background-size: cover;
      width: 100%;
      height: calc(100vh - 100px);
      overflow-y: scroll;
      // background-color: #577BBC;
    }
  }
  .bottomView {
    clear: both;
    margin-top: 0px;
    margin-left: 0px;
    width: 100vw;
    background-color: #218393;
    height: 51px;
  }
}
</style>
