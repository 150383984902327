var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"homeContainer"},[_c('div',{staticClass:"topBanner"},[_c('div',{staticClass:"logoutStyle",on:{"click":function($event){return _vm.changeView('')}}},[_vm._v("Logout")]),(_vm.user_type === 'student')?_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-left":"150px"}},_vm._l((_vm.nav),function(item){return _c('div',{key:item.name,class:{
          navInBox: true,
          selected: _vm.currentNav === item.router,
        },on:{"click":function($event){return _vm.$router.push(item.router)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e(),(_vm.user_type === 'educator')?_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-left":"150px"}},_vm._l((_vm.navContact),function(item){return _c('div',{key:item.name,class:{
          navInBox: true,
          selected: _vm.currentNav === item.router,
        },on:{"click":function($event){return _vm.$router.push(item.router)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e(),(_vm.user_type === 'employee')?_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-left":"150px"}},_vm._l((_vm.navEmployee),function(item){return _c('div',{key:item.name,class:{
          navInBox: true,
          selected: _vm.currentNav === item.router,
        },on:{"click":function($event){return _vm.$router.push(item.router)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"mainRight"},[_c('router-view')],1)]),_c('div',{staticStyle:{"clear":"both"}}),_c('div',{staticClass:"bottomView"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isChrome),expression:"!isChrome"}],staticClass:"navigator-tip-1"},[_vm._v(" ALERT: you are NOT using Google Chrome to visit this site, which might cause unexpected misfunctions or bugs. The developer of this system requires visiting via Chrome and will not be responsible for any errors on non-Chrome platforms. ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }